var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid",attrs:{"id":"TicketsLog"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card mb-4"},[_c('div',{staticClass:"card-header"},[_vm._v("Users Log ("+_vm._s(_vm.reports.length)+")")]),_c('div',{staticClass:"card-body"},[(_vm.spinner)?_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"warning","label":"Spinning"}})],1):_vm._e(),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.reports,"line-numbers":true,"search-options":{
              enabled: true,
              trigger: 'enter'
            },"pagination-options":{
              enabled: true,
              perPage: 100
            },"sort-options":{
              enabled: true,
              multipleColumns: true
            },"styleClass":"vgt-table condensed myGoodTable"},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'DateTime')?_c('span',[_vm._v(_vm._s(props.row.DateTime))]):_vm._e(),(props.column.field == 'By')?_c('span',[_vm._v(_vm._s(props.row.By))]):_vm._e(),(props.column.field == 'Action')?_c('span',[_vm._v(_vm._s(props.row.Action))]):_vm._e(),(props.column.field == 'AffectedItem')?_c('span',[_vm._v(_vm._s(props.row.AffectedItem))]):_vm._e(),(props.column.field == 'IPAddress')?_c('span',[_vm._v(_vm._s(props.row.IPAddress))]):_vm._e(),(props.column.field == 'UUID')?_c('span',[_vm._v(_vm._s(props.row.UUID))]):_vm._e()]}}])},[_c('div',{attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('div',{staticClass:"input-group mb-2 mr-sm-2"},[_c('label',{staticClass:"ml-5 light-label"},[_vm._v("Date Range:")]),_c('RangedatePicker',{staticClass:"mr-5",attrs:{"i18n":"EN","righttoleft":"true"},on:{"selected":_vm.selectedDateRange}})],1)])])],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }