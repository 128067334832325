<template>
  <div id="TicketsLog" class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-4">
          <div class="card-header">Users Log ({{ reports.length }})</div>
          <div class="card-body">
            <div v-if="spinner" class="text-center">
              <b-spinner variant="warning" label="Spinning"></b-spinner>
            </div>
            <vue-good-table
              :columns="columns"
              :rows="reports"
              :line-numbers="true"
              :search-options="{
                enabled: true,
                trigger: 'enter'
              }"
              :pagination-options="{
                enabled: true,
                perPage: 100
              }"
              :sort-options="{
                enabled: true,
                multipleColumns: true
              }"
              styleClass="vgt-table condensed myGoodTable"
            >
              <div slot="table-actions">
                <div class="input-group mb-2 mr-sm-2">
                  <label class="ml-5 light-label">Date Range:</label>
                  <RangedatePicker @selected="selectedDateRange" i18n="EN" class="mr-5" righttoleft="true" />
                  <!-- <a :href="downloadLink" class="btn btn-sm btn-secondary ml-4">Download</a> -->
                </div>
              </div>

              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'DateTime'">{{ props.row.DateTime }}</span>
                <span v-if="props.column.field == 'By'">{{ props.row.By }}</span>
                <span v-if="props.column.field == 'Action'">{{ props.row.Action }}</span>
                <span v-if="props.column.field == 'AffectedItem'">{{ props.row.AffectedItem }}</span>
                <span v-if="props.column.field == 'IPAddress'">{{ props.row.IPAddress }}</span>
                <span v-if="props.column.field == 'UUID'">{{ props.row.UUID }}</span>
                <!-- <span v-if="props.column.field == 'UserAgent'" style="font-size: 0.7rem" :title="props.row.UserAgent">{{ props.row.UserAgent }}</span> -->
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import RangedatePicker from 'vue-rangedate-picker'
import ISO8601Date from '../../helper/ISO8601Date'

export default {
  name: 'TicketsLog',

  components: {
    RangedatePicker
  },

  data() {
    return {
      reports: [],
      name: '',
      description: '',
      spinner: false,
      startDate: '',
      endDate: '',
      downloadLink: ''
    }
  },

  mounted() {
    this.loadData()
  },

  methods: {
    loadData() {
      this.spinner = true
      axios.get(`/reports/users-log?start=${this.startDate}&end=${this.endDate}`).then(res => {
        if (res.data.status == 'success') {
          this.reports = res.data.reports
          this.downloadLink = res.data.downloadLink
        }
        this.spinner = false
      })
    },

    selectedDateRange(v) {
      this.startDate = ISO8601Date(v.start)
      this.endDate = ISO8601Date(v.end)
      this.loadData()
    }
  },

  computed: {
    columns() {
      let cols = [
        {
          label: 'Date/Time',
          field: 'DateTime'
        },
        {
          label: 'Action By',
          field: 'By'
        },
        {
          label: 'Action',
          field: 'Action'
        },
        {
          label: 'IPAddress',
          field: 'IPAddress'
        },
        /*  {
          label: "Affected-Item(s)",
          field: "AffectedItem",
          tdClass: "text-center",
        }, */
        /*  {
          label: "User Agent",
          field: "UserAgent",
          sortable: false,
          width: "30%",
        }, */
        {
          label: 'Ref',
          field: 'UUID',
          sortable: false
        }
      ]
      return cols
    }
  }
}
</script>

<style></style>
